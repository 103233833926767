// change the name of this file to `index.js` once we're ready to launch the "content" website

import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/marketingLayout"
import ContainerStyles from '../components/styles/ContainerStyles'
import styled from 'styled-components';
import browser from '../assets/images/browser.svg';
import missile from '../assets/images/missile.svg';
import travel from '../assets/images/travel.svg';
import { Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

const HeroSection = styled.section`
  width: 100%;
  text-align: center;
  height 1000px;
  .col-left {
    background-color: #f56041;
    height: 800px;
  }
  .center-title {
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
  .relative-container {
    position: relative;
    padding: 10px;
  }
  #middle {
    margin: 0px 10px 0px 20px;
    float: left;
    width: 100%;
    border: 1px dashed #090;
    background-color: #cfc;
    padding: 10px;
    text-align: center;
    padding: 10px;
    text-align: center;
  }

  #bottom {
    border: 1px dashed #996;
    background-color: #ffc;
    margin: 0px 10px 0px 10px;
    text-align: left;
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  #top {
    position: absolute;
    width: 100%;
    top: 0;
    left: 40px;
    border: 1px dashed #990;
    background-color: #fdd;
    padding: 10px;
    text-align: center;
  }
  
  h5 {
    font-family: 'proxima-nova';
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-size: 18px;
    padding-top: 90px;
  }
  h1 {
    padding-top: 20px;
    color: #fff;
    text-align: center;
  }
  p {
    font-size: 22px;
    padding-top: 20px;
    color: #fff;
    text-align: center;
  }
`;

const PromiseSection = styled.section`
  h1 {
    text-align: center;
    margin-top: 80px;
  }
  p {
    text-align: center;
    margin-top: 20px;
  }
`;

const Icons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: -20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  .icon-container {
    position: relative;
    width: 100%;
    padding: 15px 0 15px 0;
    background-color: #fff;
  }
  .getting-relative {
    position: relative;
  }
  .icon {
    border-right: 1px solid #ccc;
    padding: 0px 20px 0 20px;
  }
  .icon-last {
    border-right: none;
  }
  img {
    display: block;
    margin-bottom: 0px;
    width: 100%;
    max-width: 100px;
    height: auto;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
  }
  h4 {
    font-family: 'proxima-nova';
    text-transform: none;
    text-align: center;
    padding-top: 20px;
  }
  @media (max-width: 900px) {
    display: block;
    .icon {
      border-right: none;
    }
  }
`;

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <HeroSection>     
        <div className='getting-relative'>
          <Col className='col-left' sm={4}></Col>
          <Col sm={8}></Col>
          <div className='center-title'>
            <div className='relative-container'>
              <div id='middle'>
              </div>
              <div id='bottom'>
              </div>
              <div id='top'>
                <h1>A Top Coding Education You Can Afford</h1>
              </div>
            </div>
          </div>
        </div>
      </HeroSection>
      <PromiseSection>
        <ContainerStyles>
          <Icons>
            <div className='icon-container'>
              <div className='icon'>
                <img src={browser} alt=''></img>
              </div>
              <h4>Learn to Code</h4>
            </div>
            <div className='icon-container'>
              <div className='icon'>
                <img src={travel} alt=''></img>
              </div>
              <h4>Land Your Dream Job</h4>
            </div>
            <div className='icon-container'>
              <div className='icon icon-last'>
                <img src={missile} alt=''></img>
              </div>
              <h4>Enjoy Lasting Success</h4>
            </div>
          </Icons>
          <div>
            <h1>You're Not Stuck</h1>
            <p>Too many driven people are stuck in a career they don’t enjoy with no growth potential. We have a path for you to a career in tech.</p>
          </div>
        </ContainerStyles>
      </PromiseSection>
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
